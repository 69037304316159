.documentation-page-wrapper {
  .ct-navbar {
    background-color: $ct-primary;
    box-shadow: rgba(116, 129, 141, 0.1) 0px 1px 1px 0px;
    padding-top: .5rem;
    padding-bottom: .5rem;

    @include media-breakpoint-down(md) {
      padding-right: .5rem;
      padding-left: .5rem;

      .navbar-nav-scroll {
        max-width: 100%;
        height: 2.5rem;
        margin-top: .25rem;
        overflow: hidden;
        font-size: .875rem;

        .navbar-nav {
          padding-bottom: 2rem;
          overflow-x: auto;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
        }
      }
    }

    @include media-breakpoint-up(md) {
      @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: 1049;
      }
    }

    .navbar-nav {
      .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        color: $ct-primary-light !important;

        &.active,
        &:hover {
          color: #fff !important;
          background-color: transparent !important;
        }

        &.active { font-weight: 500; }
      }
    }

    .navbar-nav-svg {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: text-top;
    }

    .dropdown-menu { font-size: .875rem; }

    .dropdown-item.active {
      font-weight: 500;
      color: $gray-900;
      background-color: transparent;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: .4rem .87rem;
      background-size: .75rem .75rem;
      padding-left: 25px;
    }
  }
}