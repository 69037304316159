.tinymce-doc-wrapper {
  order: 1;

  // ===== Headers =====
  h1 {
    border-left: 2px solid $ct-primary;
    font-weight: 300;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    padding-left: 1.25rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      font-weight: $documentation-font-weight-bold;
    }
  }

  h2, h3, h4 {
    &::before {
      content: "";
      display: block;
      height: 6rem;
      margin-top: -6rem;
      visibility: hidden;
    }
  }

  h2 { font-size: 1.5rem; margin-top: 3rem; }
  h3 { font-size: 1.25rem; margin-top: 2.5rem; }
  h4 { margin-top: 2rem; }
  h2, h3, h4, h5, h6 { font-weight: $documentation-font-weight-bold; }
  h4, h5, h6 { font-size: 1rem; }

  // ===== Table =====
  table {
    border: initial;
    margin-bottom: 1rem;
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &.table-bordered { border: 0; }
    }
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border: 1px solid $table-border-color;
          padding: $table-cell-padding;
          vertical-align: top;

          > p:last-child { margin-bottom: 0; }
        }
      }
    }

    td:first-child > code { white-space: nowrap; }
  }

  // ===== Lists =====
  ul, ol {
    li { margin-bottom: .25rem; }
  }

  @include media-breakpoint-up(lg) {
    ul, ol, p { max-width: 90%; }
  }
}
