//
// Modal
//

$big-screen-modal: 1400px;


.modal-title {
    font-size: $modal-title-font-size;
}


// Fluid modal

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-content {
        border-radius: 0;
    }
}


// Almost full creen size modal styles (BG SIZE)
.big-screen-modal {
    max-width: $big-screen-modal;
    margin-left: auto;
    margin-right: auto;

    .modal-content {
        min-height: 80vh;
    }

    @media screen and (max-width: $big-screen-modal) {
        max-width: 95vw;
    }

    @media screen and (max-width: 576px) {
        .modal-content { min-height: 95vh; }
    }
}


// Background color variations

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}
