body > .jvectormap-tip {
  background: var(--white);
  border: 0;
  box-shadow: 0 0 2px 2px rgba($black, .25);
  color: var(--gray-dark);
  font-family: var(--font-family-sans-serif);
  font-weight: 600;
  padding: 3px 5px;
}

.vector-map {
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    cursor: pointer;
    height: 15px;
    top: initial;
  }
}
