//
// Animations
//


.typing-animation {
  animation: typingAnimation 1.5s infinite ease-in-out;
}

@keyframes typingAnimation {
  0%  { transform:translateY(0) }
  28% { transform:translateY(-0.4rem) }
  44% { transform:translateY(0) }
}
