.wizard-modal-steps {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  .step-circle {
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--light);
    color: var(--light);
    display: flex;
    font-size: 1rem;
    height: 30px;
    justify-content: space-evenly;
    width: 30px;
    position: relative;

    &::before {
      position: absolute;
      content: attr(data-step-name);
      top: 100%;
      padding: .3rem;
      white-space: nowrap;
    }

    &.finished {
      background: var(--primary);
      border-color: var(--primary);
      color: var(--white);

      &::before {
        color: var(--primary);
      }
    }

    &.current {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  .step-line {
    border-bottom: 1px solid var(--light);
    flex-grow: 1;

    &.finished {
      border-color: var(--primary);
    }
  }

  &.compact {
    .step-circle {
      font-size: 0.8rem;
      height: 22px;
      width: 22px;
    }
  }
}
