@mixin arrow($deg) {
  border: solid var(--gray);
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  content: '';
  transform: rotate($deg);
  white-space: nowrap;
}

.topology-schema-wrapper,
.example-schema-wrapper {
  .right-side,
  .left-side {
    position: relative;
    border: 2px dashed var(--gray);
  }

  .cloud {
    border: 1px solid var(--gray);
    border-radius: 0.3rem;
    text-align: center;
    padding: 0.5vh;
    z-index: 2;
    background-color: white;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-height: 20px;
    max-height: 30px;
    color: var(--dark);
  }

  .divided {
    border-top: 1px solid var(--gray);
    width: 10%;
    position: relative;

    &::after {
      content: 'has_data';
      position: absolute;
      top: -17px;
      font-size: 0.7rem;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}

.topology-schema-wrapper {
  height: 300px;

  .cloud {
    position: absolute;
    text-transform: uppercase;
    width: 80%;

    &.cloud-1 {
      width: 60%;
      bottom: 10%;
      left: 20%;
      background-color: rgb(87, 199, 227);
    }

    &.cloud-2 {
      width: 26%;
      top: 45%;
      left: 37%;
      background-color: rgb(255, 196, 84);
    }

    // Category
    &.cloud-3 {
      width: 26%;
      top: 26.5%;
      left: 5%;
      background-color: rgb(247, 151, 103);
      color: var(--white);

      &::after {
        @include arrow(135deg);
        left: calc(100% + 2px);
      }
    }

    // Time period
    &.cloud-4 {
      width: 26%;
      bottom: 27%;
      right: 5%;
      background-color: rgb(236, 181, 201);

      &::after {
        @include arrow(-45deg);
        right: calc(100% + 2px);
      }
    }

    // Measure
    &.cloud-5 {
      width: 26%;
      top: 26.5%;
      right: 5%;
      background-color: rgb(76, 142, 218);
      color: var(--white);

      &::after {
        @include arrow(-45deg);
        right: calc(100% + 2px);
      }
    }

    // Geography
    &.cloud-6 {
      width: 26%;
      bottom: 27%;
      left: 5%;
      background-color: rgb(96, 74, 14);
      color: var(--white);

      &::after {
        @include arrow(135deg);
        left: calc(100% + 2px);
      }
    }
  }

  // LEFT SIDE
  .left-side {
    width: 30%;

    .cloud {
      position: initial;
      margin: 1rem auto;
      max-width: 60%;
      background-color: rgb(86, 148, 128);
      color: var(--white);
    }

    .cloud-l {
      &-1 {
        position: relative;

        &::before {
          @include arrow(-135deg);
          top: calc(100% + 2px);
        }

        &::after {
          content: '';
          position: absolute;
          border-left: 1px solid var(--gray);
          height: 160px;
          top: 100%;
          white-space: nowrap;
        }
      }

      &-2 {
        position: relative;

        &::after {
          @include arrow(45deg);
          top: -9px;
        }

        &::before {
          @include arrow(-135deg);
          top: calc(100% + 2px);
        }
      }

      &-3 {
        position: relative;

        &::after {
          @include arrow(45deg);
          top: -9px;
        }

        &::before {
          @include arrow(-135deg);
          top: calc(100% + 2px);
        }
      }

      &-4 {
        position: relative;

        &::after {
          @include arrow(45deg);
          top: -9px;
        }
      }
    }
  }

  .right-side {
    width: 60%;

    &::before {
      @include arrow(-45deg);
      transform: rotate(-45deg) translateY(-50%);;
      top: calc(50% - 1px);
      left: -9px;
    }

    .cloud {
      min-width: 100px;
    }

    // line cloud-2 CATEGORY
    .line-cloud-2 {
      &-left {
        border-top: 1px solid var(--gray);
        position: absolute;
        top: 31.35%;
        left: 31%;
        width: 12%;

        &::before {
          content: 'contains';
          position: absolute;
          top: -17px;
          font-size: 0.7rem;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }

      &-top {
        border-left: 1px solid var(--gray);
        height: 28%;
        left: 43%;
        position: absolute;
        top: 31.35%;
        z-index: 1;
      }
    }

    // line cloud-5 MEASURE
    .line-cloud-5 {
      &-right {
        border-top: 1px solid var(--gray);
        position: absolute;
        top: 31.35%;
        right: 31%;
        width: 12%;

        &::before {
          content: 'contains';
          position: absolute;
          top: -17px;
          font-size: 0.7rem;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }

      &-top {
        border-left: 1px solid var(--gray);
        height: 28%;
        right: 43%;
        position: absolute;
        top: 31.35%;
        z-index: 1;
      }
    }

    // line cloud-6 GEOGRAPHY
    .line-cloud-6 {
      &-left {
        border-top: 1px solid var(--gray);
        position: absolute;
        bottom: 31.8%;
        left: 31%;
        width: 12%;

        &::after {
          content: 'contains';
          position: absolute;
          top: 0;
          font-size: 0.7rem;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }

      &-bottom {
        border-left: 1px solid var(--gray);
        height: 28%;
        left: 43%;
        position: absolute;
        bottom: 31.8%;
        z-index: 1;
      }
    }

    // line cloud-4 TIME PERIOD
    .line-cloud-4 {
      &-right {
        border-top: 1px solid var(--gray);
        position: absolute;
        bottom: 31.8%;
        right: 31%;
        width: 12%;

        &::after {
          content: 'contains';
          position: absolute;
          top: 0;
          font-size: 0.7rem;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }

      &-bottom {
        border-left: 1px solid var(--gray);
        height: 28%;
        right: 43%;
        position: absolute;
        bottom: 31.8%;
        z-index: 1;
      }
    }
  }
}

.example-schema-wrapper {
  height: 100px;

  .left-side,
  .right-side {
    width: 45%;
  }

  .cloud {
    width: 30%;
  }

  .left-side {
    .cloud {
      &-1 {
        background-color: rgb(87, 199, 227);
        position: relative;

        &::after {
          position: absolute;
          content: '';
          left: 100%;
          border-top: 1px solid var(--gray);
          width: 100%;
          min-width: 80px;
          white-space: nowrap;
        }
      }

      &-2 {
        background-color: rgb(87, 199, 227);
        position: relative;

        &::after {
          @include arrow(-45deg);
          left: -10px;
        }
      }
    }
  }

  .right-side {
    &::before {
      @include arrow(-45deg);
      transform: rotate(-45deg) translateY(-50%);
      top: calc(50% - 1px);
      left: -8px;
    }

    .cloud {
      &-1 {
        background-color: rgb(96, 74, 14);
        color: var(--white);
        position: relative;

        &::after {
          position: absolute;
          content: '';
          left: 100%;
          border-top: 1px solid var(--gray);
          width: 100%;
          min-width: 80px;
          white-space: nowrap;
        }

        &::before {
          content: 'OBJECTID column';
          position: absolute;
          color: var(--gray);
          bottom: -15px;
          font-size: 0.55rem;
          white-space: nowrap;
        }
      }

      &-2 {
        background-color: rgb(255, 196, 84);
        position: relative;

        &::after {
          @include arrow(-45deg);
          left: -10px;
        }

        &::before {
          content: 'ALL_WEATHER_MV view';
          position: absolute;
          color: var(--gray);
          bottom: -15px;
          font-size: 0.55rem;
          white-space: nowrap;
        }
      }
    }
  }

  .line-text {
    font-size: 0.7rem;
    transform: translateY(-50%);
  }
}
