@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

// Styles for Documentation
@import './variables';
@import './nav';
@import './sidebar';
@import './tinymceContent';

.documentation-page-wrapper {
  background: #ffffff;

  // Start: Documentation Header
  .navbar-collapse {
    position: relative;

    &.collapsing,
    &.show {
      background: none;
      border-radius: $border-radius;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }
  }
  // End: Documentation Header

  // Start: Documentation left bar section

  .doc-page-acions-wrapper {
    display: none;
    margin: -5px 0;
  }

  // START: Navigation inputs EDIT mode (Update Chapter/ Add Page)
  .ct-toc-link-editable input {
    color: #0d2b3e;
  }

  .nav-item-editable {
    position: relative;

    > span {
      background-color: #4c555a;
      display: inline-block;
      height: 16px;
      width: 2px;
    }

    input {
      color: #4c555a;
      font-size: 84%;
    }
  }
  // END: Navigation inputs EDIT mode

  .ct-toc-link-editable,
  .nav-item-editable {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    &:hover {
      .doc-page-acions-wrapper {
        display: block;

        /** Fix action dropdown menu issue:
        * Add before/after elements for fix hover on second Doc page in sidebar
        * while this dropdown menu is open
        **/
        > .show {
          &::before,
          &::after {
            content: '';
            height: .9rem;
            position: absolute;
            width: 100%;
          }

          &::before { top: -.8rem; }
          &::after { bottom: -.8rem; }
        }
      }
    }

    > a { width: 100%; }
  }
  // End: Documentation left bar section

  .tinymce-doc-wrapper {
    overflow: hidden;

    img {
      border: 0;
      height: auto;
      max-width: 100% !important;
      -ms-interpolation-mode: bicubic;
    }
  }
}
