$slide-padding: 10px;

.slide-panel-overlay-wrapper {
  &.slide-pane__overlay.overlay-after-open {
    z-index: 1050;
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  }

  .slide-pane {
    min-width: 350px;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $slide-padding $slide-padding $slide-padding auto;
    height: calc(100% - #{$slide-padding * 2});
    @if $enable-shadows {
      box-shadow: 0 0 .4rem 0 rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    }

    &__header {
      background: var(--white);
      height: auto;
      flex-direction: row-reverse;
      border-radius: $border-radius $border-radius 0 0;
    }

    &__close {
      padding: 1rem 1.5rem;
      margin: 0;
    }

    &__title-wrapper { margin-left: 2rem; }
  }
}
