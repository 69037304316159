.rc-tree.rc-tree-custom {
  .rc-tree-treenode {
    .rc-tree-node-content-wrapper {
      .rc-tree-title {
        color: #140068;
      }

      .rc-tree-icon {
        &__open,
        &__close,
        &__docu {
          position: relative;
          background: none;
          font-family: 'Font Awesome 6 Free';
          font-weight: 900;
          text-align: center;
          margin-right: .5rem;
          color: #140068;

          &::before {
            inset: 0 0 0 0;
            position: absolute;
          }

          &.rc-tree-icon_loading {
            margin-top: 4px;
            &::before {
              animation: spinner-border 1.5s linear infinite;
              content: '\f110'; // Spinner
            }
          }
        }

        &__open::before { content: '\f1c0'; } // Folder Close
        &__close::before { content: '\f1c0'; } // Folder Open
        &__docu {
          font-weight: 400;
          &::before { content: '\f15b'; } // File
        }
      }

      &.rc-tree-node-selected {
        opacity: 1;
        box-shadow: none;
        background-color: transparent;
        font-weight: bold;
      }
    }

    .rc-tree-switcher {
      position: relative;

      &-noop,
      &_open,
      &_close {
        color: #140068;
        background: none;
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        text-align: center;

        &::before {
          inset: 0 0 0 0;
          position: absolute;
        }
      }

      &_open::before { content: '\f068'; } // arrow down
      &_close::before { content: '\2b'; } // arrow right
      &-noop::before { content: none; }
    }
  }
}
