.react-flow {
  &__node-default,
  &__node-input,
  &__node-output,
  &__node-group {
    &.selectable {
      &.selected,
      &:focus,
      &:focus-visible {
        box-shadow: $box-shadow-sm !important;
      }
    }
  }

  &__panel.react-flow__attribution { display: none; }

  &__handle { z-index: 1; }

  &__node {
    &.wrapper {
      border-color: $gray-600;
      color: $default;
    }

    &.opacity-5:hover {
      opacity: 1 !important;
    }

    &.deleted {
      background-color: rgba(255, 0, 0, 0.3);
    }

    &.single .react-flow__handle {
      display: none;
    }
  }

  &__mark {
    border-radius: 3px 0 0 3px !important;
    inset: -1px 0 -1px -1px;
    padding: 0 0 0 2px;
    width: 9px;

    > span {
      font-size: 5px;
      transform: rotate(-90deg);
    }
  }

  &__legend {
    width: 110px;
    z-index: 5;

    &-wrapper {
      border: 1px solid $gray-400;
      box-shadow: $box-shadow-sm;
    }

    ul {
      list-style: none;
      font-size: 12px;
    }
  }

  &__toogle {
    > i {
      transform: rotate(-90deg);
      cursor: pointer;
    }

    &.closed > i {
      transform: rotate(90deg);
    }
  }
}

.circle-status {
  border-radius: 2px;
}
