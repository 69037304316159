//
// Helper
// helper classes for different cases
//

.flex-basis-0 {
    flex-basis: 0 !important;
}

// Clearfix for sections that use float property

.floatfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

.select2-dropdown__control--is-focused {
    border-color: var(--primary) !important;
}

.select2-dropdown__menu {
    z-index: 1041 !important;
}

.custom-control {
    z-index: auto;

    &-input {
        display: none;
    }
}

.ca-hover {
    &:hover {
        color: var(--dark) !important;

        &.page-link {
            background-color: var(--lightest) !important;
        }
    }

}

.ha-info-icon {
    &:hover i {
        color: var(--info);
      }
}